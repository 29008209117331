import { supabase } from "@/config/supabase";

export const searchItems = async ({ table = null, key = null, value = null } = {}) => {
    try {

        const { data, error } = await supabase
            .from(table)
            .select()
            .eq(key, value);

        if (error) return Promise.reject(error);

        return data;

    } catch (err) {

        const error = `Error: ${err.message}`;
        return Promise.reject(error);

    }
};