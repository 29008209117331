<template>
  <header>
    <div
      class="max-w-7xl mx-auto p-4 md:flex md:items-center md:justify-between"
    >
      <h1 class="text-3xl font-bold leading-tight text-gray-900">
        {{ title }}
      </h1>
      <router-link
        v-if="add"
        :to="url"
        tag="button"
        class="flex bg-indigo-600 p-1 rounded-full items-center justify-center text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
        <span class="sr-only">{{ addTitle }}</span>
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: "MenuHeader",
  props: {
    title: String,
    add: Boolean,
    addTitle: String,
    url: String,
  },
};
</script>
