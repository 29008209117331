import { supabase } from "@/config/supabase";

export const getItem = async ({ table = null, id = null } = {}) => {
    try {

        const { data, error } = await supabase
            .from(table)
            .select()
            .eq('id', id)
            .limit(1);

        if (error) return Promise.reject(error);

        return data[0];

    } catch (err) {

        const error = `Error: ${err.message}`;
        return Promise.reject(error);

    }
};