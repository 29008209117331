import { supabase } from "@/config/supabase";

export const setItem = async ({ table = null, menu = {} } = {}) => {
    try {
        const { data, error } = await supabase
            .from(table)
            .upsert(menu);

        if (error) return Promise.reject(error);

        return data;

    } catch (err) {

        const error = `Error: ${err.message}`;
        return Promise.reject(error);

    }
};