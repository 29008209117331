import { supabase } from "@/config/supabase";

export const addItem = async ({ table = null, item = {} } = {}) => {
    try {
        const { data, error } = await supabase
            .from(table)
            .insert(item);

        if (error) return Promise.reject(error);

        return data;

    } catch (err) {

        const error = `Error: ${err.message}`;
        return Promise.reject(error);

    }
};