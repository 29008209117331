import { supabase } from "@/config/supabase";

export const deleteItem = async ({ table = null, id = null } = {}) => {
    try {

        const { data, error } = await supabase
            .from(table)
            .delete()
            .match({ id });

        if (error) return Promise.reject(error);

        return data;

    } catch (err) {

        const error = `Error: ${err.message}`;
        return Promise.reject(error);

    }
};